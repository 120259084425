
import api from '@/utils/ajax'

export const imageCaptchaAPI = (params = {}) => {
    return api.$get("/na/captcha", params)
}


export const pwdLoginAPI = (params = {}) => {
    return api.$post("/na/pwd-login", params)
}
